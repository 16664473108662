@import "styles/common.scss";

.bss-slides {
  position: relative;
  display: block;
  outline: none;
  z-index: 0;
}
.bss-slides figure {
  position: absolute;
  top: 0;
  max-width: 100%;
  display: flex;

  @media screen and (max-width: 900px) {
    display: block;
  }
}

.bss-slides figure:first-child {
  position: relative;
}

.bssSlides__imageWrapper {
  background-color: white;
  max-width: 60%;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-sm;
  opacity: 0;
  transition: opacity 1.2s;

  @media screen and (max-width: 900px) {
    height: 280px;
    max-width: 100%;
  }
}

.bss-slides figure img {
  max-width: 100%;
}

.bss-slides .bss-show .bssSlides__imageWrapper {
  opacity: 1;
}
.bss-slides figcaption {
  opacity: 0;
  margin: 0 $spacing-xl;

  .photo-label {
    font-size: $font-size-xl;
    font-weight: 500;
  }

  .photo-time {
    margin-top: $spacing-xs;
    font-size: $font-size-md;
    font-weight: 400;
    color: $grey9;
  }

  @media screen and (max-width: 900px) {
    margin: 0;
    margin-top: $spacing-xs;

    .photo-label {
      font-size: $font-size-md;
      font-weight: 500;
    }

    .photo-time {
      margin-top: $spacing-xs;
      font-size: $font-size-md;
      font-weight: 400;
      color: $grey9;
    }
  }
}
.bss-slides .bss-show figcaption {
  z-index: 2;
  opacity: 1;
}

.bss-next,
.bss-prev {
  position: absolute;
  bottom: 1%;
  z-index: 3;
  user-select: none;
  background-color: $grey5;
  width: 64px;
  padding: $spacing-sm 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-lg;
  font-size: $font-size-sm;
  background-color: $secondary-color;
  color: $primary-text-color;
  font-weight: bold;
}

.bss-next:hover,
.bss-prev:hover {
  cursor: pointer;
  opacity: 1;
  box-shadow: $box-shadow-sm;
}

.bss-next {
  right: calc(40% - 32px * 5 - 24px);
  background-color: $secondary-color;

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.bss-prev {
  right: calc(40% - 32px * 3);
  background-color: $grey6;

  @media screen and (max-width: 900px) {
    display: none;
  }
}
