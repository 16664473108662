@import "styles/common.scss";

.Blogs {
  h2 {
    font-size: $font-size-xxl;
    font-weight: 600;
    margin-top: $spacing-lg;
    margin-bottom: $spacing-lg;
  }
}
