@import "styles/common.scss";

.HomeCard {
  position: relative;
  cursor: pointer;
  z-index: 0;
  padding: $spacing-lg;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 88%;
    background-color: $grey1;
    border-radius: $border-radius-sm;
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    &::before {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    border-radius: $border-radius-sm;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $box-shadow-sm;

    img {
      max-width: 100%;
    }
  }

  &__label {
    font-size: $font-size-lg;
    font-weight: 500;
    margin-top: $spacing-lg;
  }

  &__description {
    font-size: $font-size-md;
    font-weight: 400;
    margin-top: $spacing-xs;
  }
}
