@import "styles/common.scss";

.Contact {
  margin-top: $spacing-lg;

  button {
    font-size: $font-size-md;
    padding: $spacing-sm $spacing-lg;
    border: none;
    background-color: $secondary-color;
    color: white;
    border-radius: $border-radius-lg;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: $primary-color;
    }
  }
}
