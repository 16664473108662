@import "styles/common.scss";

.AbilityCard {
  position: relative;
  z-index: 0;
  padding: $spacing-md;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 94%;
    height: 88%;
    background-color: white;
    border-radius: $border-radius-sm;
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    &::before {
      width: 100%;
      height: 100%;
    }
  }

  &__image {
    border-radius: $border-radius-sm;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $box-shadow-sm;

    img {
      max-width: 100%;
    }
  }

  &__label {
    margin-top: $spacing-sm;
    font-weight: 600;
  }

  &__technologies {
    margin-top: $spacing-xs;

    &__icon {
      color: $grey8;
      margin-right: $spacing-xs;
      margin-left: -$spacing-xxs;
      opacity: 0;

      transition: all 0.25s ease-in-out 0.25s;
    }
  }

  &:hover &__technologies {
    &__icon {
      opacity: 1;
      margin-left: 0;
    }
  }

  &_noDelay {
    transition-delay: 0s !important;
  }
}
