@import "styles/common.scss";

.GalleryImage {
  border-radius: $border-radius-xs;
  position: relative;
  padding: $spacing-sm;
  z-index: 0;
  position: relative;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 92%;
    height: 92%;
    background-color: $grey1;
    border-radius: $border-radius-sm;
    transition: all 0.25s ease-in-out;
  }

  &__wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-sm;
    overflow: hidden;
  }

  img {
    width: 100%;
    transition: all 0.35s ease-in-out;
  }

  &__hoverBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s ease-in-out;
  }

  &__details {
    margin-top: $spacing-md;

    &__time {
      font-size: $font-size-sm;
    }

    &__caption {
      margin-top: $spacing-xs;
      font-size: $font-size-md;
      font-weight: 600;
    }
  }
}
