@import "styles/common.scss";

.Home {
  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $spacing-xl;

    margin-top: $spacing-lg;
    background-color: $color1;
    padding: $spacing-xl;
    color: $primary-text-color;
    border-radius: $border-radius-md;

    &__intro {
      grid-column: 1 / span 2;

      &__heading {
        font-weight: 600;
        font-size: $font-size-xxl;
        text-transform: uppercase;
      }

      &__title {
        font-weight: 200;
        font-size: $font-size-md;
        color: $primary-text-color;
      }

      &__description {
        font-weight: 400;
        font-size: $font-size-md;
        margin-top: $spacing-md;
      }

      &__img {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 900px) {
      display: block;

      &__intro {
        &__img {
          margin-top: $spacing-lg;
          display: flex;
          justify-content: center;

          img {
            width: 50%;
          }
        }
      }
    }
  }

  &__motivations {
    margin-top: $spacing-xxl;

    &__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2 * $spacing-xl;
    }
  }

  &__moreAboutMe {
    margin-top: $spacing-xxl;

    &__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2 * $spacing-xl;
    }
  }
}
