@import "styles/common.scss";

.TextArea {
  margin-bottom: $spacing-lg;

  &_error {
    color: $color1;
    font-size: $font-size-sm;
    margin-top: 3px;
  }

  label {
    display: block;
    font-size: $font-size-sm;
    font-weight: 600;
    color: $grey7;
  }

  textarea {
    width: 100%;
    padding: $spacing-md;
    border-radius: $border-radius-sm;
    border: 1px solid $grey4;
    box-shadow: none;
    margin-top: $spacing-xs;
    font-size: $font-size-md;
    outline: none;

    &:focus {
      border: 1px solid $secondary-color;
    }

    &::placeholder {
      color: $grey5;
    }
  }
}
