@import "styles/common.scss";

.Grid {
  display: grid;
  grid-gap: $spacing-xl;

  &_2 {
    grid-template-columns: 1fr 1fr;
  }

  &_3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 900px) {
    display: block;

    &__item {
      margin-bottom: $spacing-xl;

      &_gapSm {
        margin-bottom: $spacing-sm;
      }

      &_gapLg {
        margin-bottom: $spacing-xxl;
      }
    }
  }
}
