@import "styles/common.scss";

.Skills {
  margin-top: $spacing-lg;
  h4 {
    margin-top: $spacing-lg;
  }

  button {
    font-size: $font-size-md;
    padding: $spacing-sm $spacing-lg;
    border: none;
    background-color: $secondary-color;
    color: white;
    border-radius: $border-radius-lg;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;

    &:hover {
      background-color: $primary-color;
    }
  }

  &_addForm {
    position: fixed;
    z-index: 100;

    right: 0;
    top: 0;

    background-color: white;
    height: 100vh;
    overflow-y: scroll;

    padding: $spacing-xl;
    box-shadow: $box-shadow-sm;
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;

    &__open {
      transform: translateX(0);
    }
  }

  &__intro {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__img {
      padding: 3px;
      border: 1px solid $grey5;
      border-radius: $border-radius-round;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 160px;
        height: 160px;
        border-radius: $border-radius-round;
      }
    }

    h3 {
      margin: $spacing-lg 0 $spacing-xxs;
    }
  }

  &__skills {
    &__skill {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      &__icon {
        font-size: $font-size-xxl;
        background-color: white;
        padding: $spacing-sm $spacing-lg;
        border-radius: $border-radius-sm;
      }
    }
  }

  &__experiences {
    h4 {
      margin-top: $spacing-xl;
    }

    &_heading {
      display: flex;

      &_space {
        flex-grow: 1;
      }

      button {
        background-color: transparent;
        color: $primary-color;
        outline: none;

        &:hover {
          background-color: transparent;
          color: $secondary-color;
        }
      }
    }
  }
}
