@import "styles/common.scss";

.Gallery {
  margin-top: $spacing-lg;

  h4 {
    margin-top: $spacing-xxl;
  }

  &__intro {
    &__description {
      margin-bottom: $spacing-xs;
      font-weight: 500;
      font-size: $font-size-lg;
    }
  }

  &__image {
    border-radius: $border-radius-sm;
    height: 300px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
    }
  }

  &__tag {
    display: inline-block;
    font-size: $font-size-sm;
    color: $grey8;
    border: 1px solid $grey5;
    border-radius: $border-radius-md;
    padding: $spacing-xs;
    margin-right: $spacing-sm;
    margin-bottom: $spacing-sm;
    background-color: transparent;
    cursor: pointer;

    &_selected {
      background-color: $secondary-color;
      color: $primary-text-color;
      border-color: $secondary-color;
    }
  }
}
