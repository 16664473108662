@import "../../styles/common.scss";

.Template {
  display: flex;
  min-height: 100vh;
  background-color: $main-background-color;

  &__sideNavigation {
    background-color: $secondary-color;
    display: flex;
    flex-direction: column;
    margin: $spacing-sm;
    border-radius: $border-radius-md;
    max-height: 100vh;
    transition: margin 0.15s ease-in-out;
    position: relative;

    &__bars {
      position: absolute;
      right: -50px - $spacing-md;
      top: $spacing-lg;
      background-color: $secondary-color;
      color: $primary-text-color;
      padding: $spacing-sm;
      border-radius: $border-radius-xs;
      box-shadow: $box-shadow-md;
      cursor: pointer;
      transition: background-color 0.25s ease-in-out;
      z-index: 10;
      width: 40px;

      text-align: center;

      &:hover {
        background-color: $primary-color;
      }

      display: none;

      @media screen and (max-width: 900px) {
        display: block;
      }
    }

    &__flexSpacing {
      flex-grow: 1;
    }

    &__logo {
      font-size: $font-size-lg;
      font-weight: bold;
      text-align: center;
      color: $primary-text-color;
      margin: $spacing-lg 0;
    }

    &__tabs {
      margin-top: $spacing-xl;

      &__tab {
        display: block;
        text-decoration: none;
        color: $primary-text-color;
        padding-right: $spacing-sm;
        margin-left: $spacing-md;
        border-radius: 25px 0 0 25px;
        padding: $spacing-sm;
        position: relative;

        &__boxAbove {
          height: 12px;
          width: 12px;
          background-color: $main-background-color;
          border-radius: 0;

          position: absolute;
          top: -12px;
          right: 0;

          &::before {
            content: "";
            height: 12px;
            width: 12px;
            background-color: $secondary-color;
            border-radius: 0 0 $border-radius-md 0;

            position: absolute;
            left: 0;
            top: 0;
          }
        }

        &__boxBelow {
          height: 12px;
          width: 12px;
          background-color: $main-background-color;
          border-radius: 0;

          position: absolute;
          bottom: -12px;
          right: 0;

          &::before {
            content: "";
            height: 12px;
            width: 12px;
            background-color: $secondary-color;
            border-radius: 0 $border-radius-md 0 0;

            position: absolute;
            left: 0;
            top: 0;
          }
        }

        &_active {
          background-color: $main-background-color;
          color: $secondary-color !important;
          position: relative;
        }
      }

      &__tab:hover {
        &::before {
          content: "";
          height: 24px;
          width: 8px;
          background-color: $main-background-color;
          border-radius: $border-radius-md;

          position: absolute;
          left: -$spacing-md - 4px;
          top: calc(50% - 12px);
        }
      }

      &__icon {
        font-size: $font-size-sm;
        margin-right: $spacing-md;
      }
    }

    &__socials {
      margin-bottom: $spacing-xl;

      &__button {
        font-size: $font-size-md;
        text-align: center;
        color: $primary-text-color;
        font-size: $font-size-md;
        padding: $spacing-sm 0;
      }
    }

    @media screen and (max-width: 900px) {
      margin: $spacing-xs;

      &_hidden {
        margin-left: -80px;
      }
    }
  }

  &__scrollable {
    flex-grow: 1;
    overflow: scroll;
    max-height: 100vh;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    margin: 0 auto;
    margin-top: $spacing-xl;
    max-width: 960px;
    padding: 0 $spacing-lg;

    &__top {
      display: flex;
      align-items: center;

      &__emptySpace {
        flex-grow: 1;
      }

      &__getInTouch {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          width: 40px;
          border-radius: $border-radius-md;
          margin-left: $spacing-xs;
          box-shadow: $box-shadow-md;
        }
      }
    }

    &__children {
      min-height: calc(100vh - 210px);
    }

    &__footer {
      margin-top: $spacing-xl * 2;
      text-align: center;
      margin-bottom: $spacing-xl;

      &__divider {
        width: 20vw;
        margin: 0 auto;
        height: 2px;
        background-color: $grey4;
      }

      &__copyright {
        margin-top: $spacing-sm;
      }
    }

    @media screen and (max-width: 900px) {
      padding: 0 $spacing-md;
    }
  }
}
