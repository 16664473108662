@import "styles/common.scss";

.ExperienceCard {
  display: flex;
  padding: $spacing-md;
  background-color: $grey1;
  border-radius: $border-radius-sm;

  &__img {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-self: center;

    img {
      display: block;
      width: 50px;
      margin: 0 auto;
    }
  }

  &__details {
    flex-grow: 6;
    margin-left: $spacing-md;

    &__title {
      font-weight: 600;
    }
  }
}
