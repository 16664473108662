@import "styles/common.scss";

.Loader {
  margin: 0 auto;
  width: 160px;
  height: 5px;
  border: 1px solid $grey4;
  border-radius: 50px;
  overflow: hidden;

  &__fill {
    animation: slide 1.5s linear infinite;
    background-color: $secondary-color;
    width: 50%;
    border-radius: $border-radius-lg;
    height: 100%;
  }
}

@keyframes slide {
  0% {
    margin-left: -50%;
  }
  100% {
    margin-left: 100%;
  }
}
