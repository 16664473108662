@import "styles/common.scss";

.OverviewBlock {
  border-radius: $border-radius-sm;
  padding: $spacing-xs;
  position: relative;
  color: black;
  z-index: 0;

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90%;
    height: 95%;
    background-color: white;
    border-radius: $border-radius-sm;
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    &::before {
      width: 100%;
      height: 100%;
    }
  }

  &_color1_active {
    border: 1px solid $color1;
  }

  &_color2_active {
    border: 1px solid $color2;
  }

  &_color3_active {
    border: 1px solid $color3;
  }

  &__contentWrapper {
    display: flex;
    align-items: center;

    &__iconWrapper {
      flex-grow: 1;
      margin-right: $spacing-sm;
      display: flex;
      align-items: center;

      &__icon {
        padding: $spacing-md;

        width: 54px;
        height: 54px;
        text-align: center;
        border-radius: $border-radius-sm;
        color: white;
        position: relative;
        font-size: $font-size-sm;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          // content: "";
          position: absolute;
          width: 58px;
          height: 58px;

          border-radius: $border-radius-sm;
          top: -2px;
          right: -2px;
          z-index: 1;
        }

        &_color1 {
          background-color: $color1;

          &::before {
            background-color: $color1;
            opacity: 0.2;
          }
        }

        &_color2 {
          background-color: $color2;

          &::before {
            background-color: $color2;
            opacity: 0.2;
          }
        }

        &_color3 {
          background-color: $color3;

          &::before {
            background-color: $color3;
            opacity: 0.2;
          }
        }
      }
    }

    &__content {
      flex-grow: 2;

      &__number {
        font-weight: 600;
      }

      &__label {
        font-weight: 400;
      }
    }
  }
}
