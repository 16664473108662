@import "styles/common.scss";

.SelectableTag {
  display: inline-block;
  font-size: $font-size-sm;
  color: $grey8;
  border: 1px solid $grey5;
  border-radius: $border-radius-md;
  padding: $spacing-xs;
  margin-right: $spacing-sm;
  margin-bottom: $spacing-sm;
  background-color: transparent;
  cursor: pointer;

  &_selected {
    background-color: $secondary-color;
    color: $primary-text-color;
    border-color: $secondary-color;
  }
}
